@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  h1,
  h2,
  h3 {
    @apply font-cursive;
  }

  p {
    @apply tracking-wide;
  }
}
